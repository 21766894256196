import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'semantic-ui-react'

import Problem from './Problem'

const LayerProblems = ({ layerProblems, compName }) => {
  const { layerName, problems } = layerProblems
  return (
    <List.Item>
      <List.Icon name="triangle right" />
      <List.Content>
        <List.Header>{layerName}</List.Header>
        <List.List>
          {problems.map((problem) => (
            <Problem key={problem.message} problem={problem} layerName={layerName} compName={compName} />
          ))}
        </List.List>
      </List.Content>
    </List.Item>
  )
}

LayerProblems.propTypes = {
  layerProblems: PropTypes.object,
  compName: PropTypes.string
}

const CompProblems = ({ compProblems }) => {
  const { compName, layersProblems } = compProblems
  return (
    <List.Item>
      <List.Icon name="folder open outline" />
      <List.Content>
        <List.Header>{compName}</List.Header>
        <List.List>
          {layersProblems.map((problems, i) => (
            <LayerProblems key={i + problems.layerName} layerProblems={problems} compName={compName}/>
          ))}
        </List.List>
      </List.Content>
    </List.Item>
  )
}

CompProblems.propTypes = {
  compProblems: PropTypes.object
}

export default CompProblems
