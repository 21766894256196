const noop = function (...args) {
  console.log('NOOP', args)
}

function getCSInterface () {
  const isAfterEffectsEnvironment = typeof CSInterface !== 'undefined'
  if (isAfterEffectsEnvironment) {
    return new CSInterface()
  };

  return {
    isFake: true,
    evalScript: noop,
    addEventListener: noop
  }
}

const csInterface = getCSInterface()
export default csInterface
