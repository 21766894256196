import React, {
  createContext,
  useState,
  useContext,
  useEffect
} from 'react'
import PropTypes from 'prop-types'

import getProjectFilepath from '../util/getProjectFilepath'

const ApplicationContext = createContext()

export const useApplicationContext = () => useContext(ApplicationContext)

const ApplicationContextProvider = ({ children }) => {
  const [projectFilepath, setProjectFilepath] = useState(null)

  useEffect(() => {
    const changeProjectFilepathIfProjectHasChanged = async () => {
      const newProjectFilepath = await getProjectFilepath()
      const decodedNewProjectFilepath = decodeURI(newProjectFilepath)
      const projectHasChanged = projectFilepath !== decodedNewProjectFilepath
      if (projectHasChanged) {
        setProjectFilepath(decodedNewProjectFilepath)
      }
    }

    const intervalId = setInterval(changeProjectFilepathIfProjectHasChanged, 1000)

    return () => {
      clearInterval(intervalId)
    }
  }, [projectFilepath, setProjectFilepath])

  return (
    <ApplicationContext.Provider
      value={{
        projectFilepath
      }}
    >
      { children }
    </ApplicationContext.Provider>
  )
}

ApplicationContextProvider.propTypes = {
  children: PropTypes.node
}

export default ApplicationContextProvider
