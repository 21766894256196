import React, {
  createContext,
  useContext,
  useState,
  useLayoutEffect
} from 'react'
import PropTypes from 'prop-types'

import { useApplicationContext } from './ApplicationContext'
import csInterface from '../util/CSInterface'
import lint from '../extendscript/lint'

const LinterContext = createContext()

export const useLinterContext = () => useContext(LinterContext)

const LinterContextProvider = ({ children }) => {
  const { projectFilepath } = useApplicationContext()
  const [hasProblems, setHasProblems] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [problems, setProblems] = useState({})
  const [errorOnParsing, setErrorOnParsing] = useState(null)

  const onCheckForProblems = () => {
    setIsLoading(true)
    setTimeout(() => {
      csInterface.evalScript(lint, function (returnValue) {
        try {
          const { linterProblems = [], error = null } = JSON.parse(returnValue)
          const problemsPerComp = linterProblems.reduce((previous, current) => {
            const { compName } = current
            const compProblems = previous[compName] || { compName: compName }
            const previousProblems = compProblems.layersProblems || []

            return {
              ...previous,
              [compName]: {
                ...compProblems,
                layersProblems: [...previousProblems, current]
              }
            }
          }, {})
          setProblems(problemsPerComp)
          setHasProblems(linterProblems.length > 0)
          setErrorOnParsing(error)

          if (error) {
            console.error('ExtendScript Error: ' + error.message)
            console.error(error)
          }
        } catch (error) {
          console.error(error)
          setErrorOnParsing(true)
        }
        setIsLoading(false)
      })
    })
  }

  useLayoutEffect(() => {
    onCheckForProblems()
  }, [projectFilepath])

  return (
    <LinterContext.Provider value={{
      hasProblems,
      isLoading,
      problems,
      errorOnParsing,
      onCheckForProblems
    }}>
      { children }
    </LinterContext.Provider>
  )
}

LinterContextProvider.propTypes = {
  children: PropTypes.node
}

export default LinterContextProvider
