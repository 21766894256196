import React from 'react'

import { Container, Header } from 'semantic-ui-react'
import Linter from './components/Linter'
import { useApplicationContext } from './components/ApplicationContext'
import LinterContextProvider from './components/LinterContext'

import './App.styl'

export default function App () {
  const { projectFilepath } = useApplicationContext()
  const projectFilename = projectFilepath?.split('/').pop()

  return (
    <Container className="app-container" fluid>
      <div className="logocontainer">
        <img className="logo" src="https://storage.videomatik.com.br/static-assets/videomatik-logo-white.svg"/>
      </div>
      <Container className="content">
        <Header as="h3" inverted>{ projectFilename }</Header>
        <LinterContextProvider>
          <Linter/>
        </LinterContextProvider>
      </Container>
      <span className="version">Versão {VERSION_HASH}</span>
    </Container>
  )
}
