import textLeadingAutoImage from '../images/textLeadingAuto.png'
import textChangeColorImage from '../images/textChangeColor.png'
import csInterface from '../util/CSInterface'

const problemCodeMap = {
  TEXT_LEADING_AUTO: {
    autoFix: (layerData) => {
      return new Promise((resolve) => {
        csInterface.evalScript('autoFixTextLeading(' + JSON.stringify(layerData) + ')', resolve)
      })
    },
    image: {
      src: textLeadingAutoImage,
      height: 35
    }
  },
  TEXT_STATIC_FILL_EFFECT: {
    autoFix: (layerData) => {
      return new Promise((resolve) => {
        csInterface.evalScript('autoFixTextStaticFillEffect(' + JSON.stringify(layerData) + ')', resolve)
      })
    },
    image: {
      src: textChangeColorImage,
      height: 60
    }
  },
  SHAPE_STATIC_FILL_EFFECT: {
    autoFix: (layerData) => {
      return new Promise((resolve) => {
        csInterface.evalScript('autoFixShapeStaticFillEffect(' + JSON.stringify(layerData) + ')', resolve)
      })
    }
  }
}

const getProblemExtra = (problemCode) => {
  return problemCodeMap[problemCode] || {}
}

export default getProblemExtra
