import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

import 'semantic-ui-css/semantic.min.css'
import './assets/styles/index.styl'

import csInterface from './util/CSInterface'
import jsonInject from './extendscript/libs/json'
import consoleInject from './extendscript/console'
import autoFix from './extendscript/autoFix'
import ApplicationContextProvider from './components/ApplicationContext'

csInterface.evalScript(jsonInject)
csInterface.evalScript(consoleInject)
csInterface.evalScript(autoFix)

csInterface.addEventListener('console.log', function (event) {
  const length = event.data.length
  const parsedArguments = []
  for (let i = 0; i < length; i++) {
    parsedArguments.push(event.data[i])
  }
  console.log('ExtendScript:', ...parsedArguments)
})

ReactDOM.render(
  <ApplicationContextProvider>
    <App/>
  </ApplicationContextProvider>,
  document.getElementById('root')
)
