import React from 'react'
import { List, Icon } from 'semantic-ui-react'
import Button from './Button'
import CompProblems from './linter/CompProblems'

import { useLinterContext } from './LinterContext'

import './Linter.styl'

export default function Linter () {
  const {
    hasProblems,
    isLoading,
    problems,
    errorOnParsing,
    onCheckForProblems
  } = useLinterContext()

  const compsNames = Object.keys(problems)
  return (
    <div className="linter">
      <Button
        primary
        content="Verificar Problemas"
        onClick={onCheckForProblems}
        loading={isLoading}
      />
      {isLoading && <span> Verificando problemas...</span>}
      <List inverted>
        {errorOnParsing && (
          <List.Item icon={<Icon name="times circle" className="redError" />} content="Ocorreu um erro ao verificar este projeto. Por favor, tente novamente, se o erro persistir contate o time do Videomatik e encaminhe este projeto para ser analisado."/>
        )}
        {!hasProblems && !errorOnParsing && !isLoading && (
          <List.Item icon={<Icon name="check circle" className="greenCheck" />} content="Nenhum problema encontrado"/>
        )}
        {!errorOnParsing && compsNames.map((compName, i) => (
          <CompProblems key={compName} compProblems={problems[compName]} />
        ))}
      </List>
    </div>
  )
}
