import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  Button as SUIButton
} from 'semantic-ui-react'

import './Button.styl'

export default function Button ({
  primary,
  secondary,
  tertiary,
  className,
  loading,
  disabled,
  text,
  grayText,
  onClick,
  ...props
}) {
  return (
    <SUIButton
      primary={primary}
      secondary={secondary}
      basic={tertiary}
      className={classnames({ tertiary, text: text || grayText, 'gray-text': grayText }, className)}
      loading={loading}
      disabled={disabled}
      onClick={onClick}
      {...props}
    />
  )
}

Button.propTypes = {
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  tertiary: PropTypes.bool,
  text: PropTypes.bool,
  grayText: PropTypes.bool,
  className: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}
