import csInterface from '../util/CSInterface'

const getProjectNameScript = `
var projectFile = app.project.file;
var projectFilepath = projectFile.path + '/' + projectFile.name;
projectFilepath;
`

export default async function getProjectFilepath () {
  return new Promise((resolve) => {
    csInterface.evalScript(getProjectNameScript, resolve)
  })
}
