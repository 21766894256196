import React from 'react'
import PropTypes from 'prop-types'
import { List, Icon, Image } from 'semantic-ui-react'

import Button from '../Button'
import { useLinterContext } from '../LinterContext'
import getProblemExtra from '../../constants/getProblemExtra'

const getProblemIcon = (level) => {
  if (level === 'error') {
    return <List.Icon name="times circle" className="redError" />
  }
  if (level === 'warning') {
    return <List.Icon name="exclamation circle" className="yellowWarning" />
  }
}

const getProblemClassName = (level) => {
  if (level === 'error') {
    return 'redError'
  }
  if (level === 'warning') {
    return 'yellowWarning'
  }
}

const Problem = ({ problem, layerName, compName }) => {
  const { message, solution, level, problemCode, layerIndex } = problem
  const isSolutionString = typeof solution === 'string'
  const icon = getProblemIcon(level)
  const { image, autoFix } = getProblemExtra(problemCode)
  const {
    onCheckForProblems
  } = useLinterContext()

  return (
    <List.Item>
      {icon}
      <List.Content>
        <List.Header className={getProblemClassName(level)}>{message}</List.Header>
        <List.List>
          {isSolutionString && <List.Item icon={<Icon name="triangle right"/>} content={solution} />}
          {!isSolutionString && <List.Item>
            <Icon name="triangle right"/>
            <List.Content>
              <List.Header>{ solution.header }</List.Header>
              <List.List>
                {solution.items.map((solutionItem, i) => {
                  return <List.Item key={i} icon={<Icon name="triangle right"/>} content={solutionItem} />
                })}
              </List.List>
            </List.Content>
          </List.Item>}
          <div className="extra-content">
            {image && <Image {...image}/>}
            {autoFix && <div className="button-container">
              <Button onClick={() => {
                autoFix({ compName, layerIndex, layerName }).then(() => {
                  onCheckForProblems()
                })
              }}>Corrigir</Button>
            </div>}
          </div>
        </List.List>
      </List.Content>
    </List.Item>
  )
}

Problem.propTypes = {
  problem: PropTypes.object,
  layerName: PropTypes.string,
  compName: PropTypes.string
}

export default Problem
